<template>
    <BT-Blade
        flexColumn
        hideBackButton
        :loading="isLoading"
        title="Timeline">
        <template>
            
            <g-gantt-chart
                :chart-start="myChartStart"
                :chart-end="myChartEnd"
                :grid="true"
                hideTimeaxis>
                <g-gantt-row
                    v-for="(row, index) in rows"
                    :key="index"
                    :label="row.label"
                    :bars="row.bars"
                    bar-start="myStart"
                    bar-end="myEnd" />
            </g-gantt-chart>

            <BT-Snack v-model="msg" />

        </template>
    </BT-Blade>

</template>

<script>
import { GGanttChart, GGanttRow } from 'vue-ganttastic';
import * as cronjsMatcher from '@datasert/cronjs-matcher';

export default {
    name: 'Product-Board',
    components: {
        //VueDraggableResizable: () => import('vue-draggable-resizable'),
        GGanttChart,
        GGanttRow
    },
    data: function() {
        return {
            allSlots: [],
            blocks: [],
            isLoading: false,
            myChartStart: null, //'2020-03-01 00:00',
            myChartEnd: null, //'2021-03-03 00:00',
            msg: null,
            rows: [{
                label: "My row #1",
                bars: [{
                    myStart: "2020-03-01 12:10",
                    myEnd: "2020-03-01 16:35" }]
            },
            {
                label: "My row #2",
                bars: [{
                    myStart: "2020-03-02 01:00",
                    myEnd: "2020-03-02 12:00" 
                },
                {
                    myStart: "2020-03-02 13:00",
                    myEnd: "2020-03-02 22:00"
                }]
            }]
        }
    },
    async mounted() {
        try {
            this.isLoading = true;
            this.allSlots = await this.$BlitzIt.store.getAll('order-slots', { includeDetails: true });
            var raw = this.$BlitzIt.auth.createRawTZ();
            this.myChartStart = raw.toFormat('yyyy-LL-dd HH:mm');
            this.myChartEnd = raw.plus({ days: 7 }).toFormat('yyyy-LL-dd HH:mm');
            this.loadSlots();
        }
        catch (err) {
            this.msg = this.extractErrorDescription(err);
        }
        finally {
            this.isLoading = false;
        }
    },
    methods: {
        convertToRow(slot) {
            var future = cronjsMatcher.getFutureMatches(this.getCronExpression(slot.cronExpression), { matchCount: 1, formatInTimeZone: true });

            var newSlot = {
                label: slot.slotName,
                bars: [
                    {
                        myStart: this.$BlitzIt.auth.createRawTZ(future[0]).toFormat('yyyy-LL-dd HH:mm'),
                        myEnd: this.$BlitzIt.auth.createRawTZ(future[0]).plus({ hours: 12 }).toFormat('yyyy-LL-dd HH:mm')
                    }
                ]
            }

            return newSlot;
        },
        loadSlots() {
            this.rows = [];

            this.allSlots.forEach(slot => {
                var newRow = this.convertToRow(slot);

                if (newRow != null) {
                    this.rows.push(newRow);
                }
                
                //var future = cronjsMatcher.getFutureMatches(this.getCronExpression(this.cronExpression), { matchCount: 5, formatInTimeZone: true });

                // future.forEach(f => {
                //     f = this.$BlitzIt.auth.isNotUTC(f);
                //     newRow.bars.push({
                //         myStart: f,
                //         myEnd: '2022'
                //     })
                //     var futureDate = this.$BlitzIt.auth.formISO(f);
                //     futureDate = this.$BlitzIt.auth.formTZtoUTC(futureDate);
                // })

                this.rows.push(newRow);
            });
        }
        // async openSlotGroup(bladeData, item) {
        //     try {
        //         this.isLoading = true;

        //         var grp = await this.$BlitzIt.store.get('order-slots');


        //     }
        //     catch (err) {
        //         this.msg = this.extractErrorDescription(err);
        //     }
        //     finally {
        //         this.isLoading = false;
        //     }
        //     this.rows.push({
        //         label: item.groupName
        //     })
        // }
        // openProductGroupBox(item) { //, idProp, navigation = 'products', sortProp = 'productName', titleProp = 'groupName') {
        //     var idProp = 'productOfferingGroupID';
        //     var navigation = 'products';
        //     var sortProp = 'sortNumber';
        //     var titleProp = 'groupName';
        //     var itemText = 'productName';

        //     this.blocks = this.blocks.filter(x => x.idProp == idProp && x.navigation == navigation);
            
        //     if (!this.blocks.some(y => y.item.id == item.id)) {
        //         this.blocks.push({
        //             item,
        //             idProp,
        //             itemText,
        //             navigation,
        //             onDrop: async(navigation, itemToAdd, group, list) => {
        //                 if (list.some(y => y.id == itemToAdd.id)) {
        //                     return;
        //                 }

        //                 await this.$BlitzIt.api.post(
        //                     'product-groups',
        //                     { productOfferingGroupID: group.id, productID: itemToAdd.id },
        //                     null,
        //                     '/post/Add');

        //                 list.push(itemToAdd);
        //             },
        //             onFilter: list => { return list },
        //             onPull: async(nav, item) => {
        //                 var res = await this.$BlitzIt.store.get('product-groups', item.id);
        //                 return res.products;
        //             },
        //             onRemove: async(group, itemToRemove, list) => {
        //                 if (itemToRemove == null || group == null) {
        //                     return;
        //                 }

        //                 await this.$BlitzIt.api.post(
        //                     'product-groups',
        //                     { productOfferingGroupID: group.id, productID: itemToRemove.id },
        //                     null,
        //                     '/post/Remove');

        //                 var ind = list.findIndex(y => y.id == itemToRemove.id);
        //                 if (ind > -1) {
        //                     list.splice(ind, 1);
        //                 }
        //             },
        //             sortProp,
        //             titleProp
        //         });
        //     }

        //     var supplyBlock = this.supplyBlocks.find(y => y.navigation == navigation);
        //     if (supplyBlock == null) {
        //         supplyBlock = {
        //             idProp: idProp,
        //             itemText: itemText,
        //             navigation: navigation,
        //             onFilter: list => {
        //                 return list.filter(l => !this.existingProducts.some(z => z.id == l.id))
        //             },
        //             sortProp: sortProp,
        //             title: this.$BlitzIt.navigation.findDisplayName(navigation),
        //         }

        //         this.supplyBlocks.push(supplyBlock);
        //     }
        //     else {
        //         supplyBlock.idProp = idProp;
        //     }
        // }
    }
}
</script>